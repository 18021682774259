@use '../config/' as *;
@forward './common';
// styles specifically for login page
.step-1 {
    .form-step2 {
        @extend %d-none;
    }
}
.step-2 {
    .form-step1 {
        @extend %d-none;
    }
}
.waf-login {
    @extend %white-bg;
}
.resend-otp {
    @extend %relative;
}
.exceeded-limit {
    @extend %p-3;
    @extend %white-bg;
    @extend %full-radius;
    @extend %flex-n-fs;
    @extend %gap-3;
    @include position(null, var(--space-3), -7rem, null);
    .info {
        @extend %flex-column;
        &-title {
            @extend %font-14-pb;
            @extend %secondary;
        }
        &-desc {
            @extend %font-12-pr;
            @extend %secondary-dark;
        }
    }
    .btn-icon {
        &::after {
            @include icon(close, 12);
        }
    }
    .icon {
        &::before {
            content: 'i';
            width: 2.5rem;
            height: 2.5rem;
            pointer-events: none;
            aspect-ratio: 1;
            @extend %font-10-pr;
            @extend %half-radius;
            @extend %flex-c-c;
            @extend %warning-bg;
        }
    }
}
@include mq(col-md) {
    // styles specifically for login page
    .step-1 {
        width: 80%;
        margin: auto;
        .form {
            &-list {
                padding-left: 0;
            }
            &-mobile {
                grid-column-end: span 2;
            }
            &-wrapper {
                padding-block: 0;
            }
            &-banner {
                max-width: 50rem;
            }
        }
    }
}